





















































import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { DataResponseCalendar } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import Vue from "vue";
export default Vue.extend({
  data() {
    this.getListCalendar = debounceProcess(this.getListCalendar, 200);
    return {
      loadingCalendar: false as boolean,
      dataCalendar: [] as DataResponseCalendar[],
      loadingOpen: false as boolean,
      loadingClose: false as boolean,
      form: this.$form.createForm(this, { name: "closePeriod" }),
      formRules: {
        period: {
          label: "lbl_period",
          name: "period",
          placeholder: "lbl_choose",
          decorator: [
            "period",
            {
              rules: [
                {
                  required: true,
                  message: (): string => this.$t("lbl_validation_required_error").toString(),
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
      };
    },
  },
  mounted() {
    this.getListCalendar("");
  },
  methods: {
      filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCancel(): void {
      // console.log("cancel");
    },
    // open(): void {
    //   console.log('open');

    // },
    close(): void {
      this.form.validateFields((err , value) => {
        if (!err){
          logisticServices.closePeriodInventory(`?period=${value.period}`).then(()=> {
            this.$message.success(Messages.UPDATE_SUCCESS);
            this.form.resetFields();
          });
        }
      });
    },
    getListCalendar(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `month~*${valueSearch}*`;
      this.loadingCalendar = true;
      let dataPeriode = [] as any;
      this.dataCalendar = [];
      assetsServices
        .listCalendar(params)
        .then((data) => {
          data.data.forEach((element) => {
            if (!element.month.includes("Adj")) dataPeriode.push(element);
          });
          this.dataCalendar = dataPeriode;
        })
        .finally(() => (this.loadingCalendar = false));
    },
  },
});
